

/*!* Начальное состояние *!*/
/*.blockText-transition-enter, .blockText-transition-exit-active {*/
/*    !*opacity: 0;*!*/
/*    height: 0px;*/
/*    !*transform: scaleY(0);*!*/
/*    !*transform: rotateZ(90deg);*!*/
/*    overflow: hidden;*/
/*    !*display: block; !* или другое значение в зависимости от вашей верстки *!*!*/
/*}*/

/*!* Конечное состояние для появления *!*/
/*.blockText-transition-enter-active {*/
/*    !*opacity: 1;*!*/
/*    height: 61px;*/
/*    !*transform: scaleY(1);*!*/
/*    !*transform: rotateZ(0deg);*!*/
/*    transition:transform 1000ms ease-in-out, height 1000ms ease-in-out;*/
/*;*/
/*}*/

/*!* Начальное состояние для исчезновения *!*/
/*.blockText-transition-exit {*/
/*    height: 61px;*/
/*    !*opacity: 1;*!*/
/*    !*transform: scaleY(1);*!*/
/*    !*transform: rotateZ(0deg);*!*/
/*    overflow: hidden;*/

/*}*/

/*!* Конечное состояние для исчезновения *!*/
/*.blockText-transition-exit-active {*/
/*    !*opacity: 0;*!*/
/*    height: 0px;*/
/*    !*transform: scaleY(0);*!*/
/*    !*transform: rotateZ(90deg);*!*/
/*    transition:transform 1000ms ease-in-out, height 1000ms ease-in-out;*/
/*;*/
/*}*/

/*.blockText-transition-enter-done {*/
/*    !* Сюда можно добавить стили, которые будут применены после завершения "входящей" анимации *!*/
/*    !*opacity: 1;*!*/
/*    height: 61px;*/
/*    !*transform: rotateZ(0deg);*!*/
/*    !*transform: scaleY(1);*!*/
/*}*/

/*.blockText-transition-exit-done {*/
/*    !* Сюда можно добавить стили, которые будут применены после завершения "исходящей" анимации *!*/
/*    !*opacity: 0;*!*/
/*    height: 0px;*/
/*    !*display: none;*!*/
/*    !*transform: scaleY(0);*!*/
/*    !*transform: rotateZ(90deg);*!*/
/*    overflow: hidden;*/
/*}*/

.unshow_block{
    opacity: 0;
    transition: opacity 500ms ease-in-out;

}

.show_block{
    opacity: 1;
    transition: opacity 500ms ease-in-out;
}