@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Unica+One&display=swap");

.page {
    min-height: 100vh;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 48px auto;
}

a {
    color: inherit;
    text-decoration: none;
}

.markdown-to-html-wrapper p,
.markdown-to-html-wrapper span,
.markdown-to-html-wrapper h1,
.markdown-to-html-wrapper h2,
.markdown-to-html-wrapper h3,
.markdown-to-html-wrapper h4,
.markdown-to-html-wrapper h5,
.markdown-to-html-wrapper h6,
.markdown-to-html-wrapper code {
    margin: 0;
    font-family: "Roboto", Arial, Helvetica, sans-serif;
}

pre {
    text-wrap: wrap;
    word-break: break-all;
}

@media (max-width: 768px) {
    .contact-sales-button {
      display: none;
    }
}

.glow {
    box-shadow: 0 0 30px rgba(0, 76, 255, 0.9);
}

.gradient-text {
    background: linear-gradient(to right, #000066, #0000FF);
    -webkit-background-clip: text;
    color: transparent;
    display: inline;
}

.footer-link {
    color: lightgray;
    text-decoration: none;
    margin: 1rem;
  }
  
.footer-link:hover {
    color: black;
    text-decoration: underline;
}