#header #logo img {
    max-height: 50px;
}

#header a:hover,
#popover-menu a:hover {
    text-decoration: none;
}

a.active {
    display: none;
}

@media screen and (max-width: 450px) {
    #header #logo img {
        max-height: 35px;
    }
}
