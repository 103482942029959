/* selected */
.react-flow__node.selected {
  box-shadow: 0px 0px 5px rgb(108, 108, 110);
  border-radius: 10px;
}

.node-border {
  border: 2px solid rgb(43, 142, 241);
  border-radius: 10px;
  width: 250px;
  cursor: pointer;
}

.flex-box{
  display: flex;
  padding: 5px;
  justify-content: center;
  gap: 10px;
}


.node-header {
  color:  rgb(43, 142, 241);
  font-size: 1.1em;
  inline-size: 180px; 
  overflow-wrap: anywhere;
}

.node-text {
  font-size: 1em;
  inline-size: 180px; 
  overflow-wrap: anywhere;
}

.icon-box {
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
}
.process-symbol-box {
  display: flex;
  padding: 5px;
  justify-content: center;
  align-items: flex-start;
}

.process-symbol {
  height: 5px;
  width: 5px;
  text-align: center;
  border: 1px solid rgb(43, 142, 241);
  border-radius: 50%;
  font-size: 0.4rem;
  color:white;
  background-color: rgb(43, 142, 241);
}
/* connection points */
.react-flow .react-flow__handle {
  width: 5px;
  height: 5px;
  border: 0px;
  border-radius: 50%;
  background-color: #477ecb;
}

.react-flow .react-flow__handle-top {
  top: -1.5px;
}

.react-flow .react-flow__handle-bottom {
  bottom: -1.5px;
}

.react-flow .react-flow__handle-left {
  left: -1.5px;
}

.react-flow .react-flow__handle-right {
  right: -1.5px;
}

.filter-box {
  background: lightgray;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  opacity: 50%;
  padding-top: 0.5em;
  padding-right: 0.5em;
  display: flex;
  justify-content: right;
  align-items: start;
}